import ticketCode from '../../components/ticketCode.vue'
import lablePop from '../../components/lablePop.vue' //弹窗
import ptBtnYq from '../../components/ptBtn.vue' //优惠券
export default {
    name: 'share',
    components: {
        ticketCode,
        lablePop,
        ptBtnYq
    },
    data() {
        return {
            orderInfo: null,
            tickeShow: false, //显示二维码
            id: null,
            pop_show: false,
            timer: ['00', '00', '00', '00'],
            user_data: null
        }
    },
    created() {
        document.title = "团购门票";
        !localStorage.getItem('mall_id') ? localStorage.setItem('mall_id', this.$route.query.mall_id) : '';
        localStorage.removeItem('login_data');
        this.getOrderInfo();
    },
    methods: {
        toHome() {
            let _this = this;
            _this.$route.query.mall_id = localStorage.getItem('mall_id');
            _this.$router.push({
                name: 'home',
                query: _this.$route.query
            })
        },
        hidePop(e) {
            this.pop_show = false;
        },
        //出示二维码
        playSY(id) {
            this.id = id;
            this.tickeShow = true;
        },
        //关闭二维码弹窗
        IsCode(e) {
            this.tickeShow = false;
        },
        //判断订单是否过期
        getTimerState(order_time) {
            var timestamp = Date.parse(new Date()) / 1000;
            return timestamp - order_time
        },
        //判断是否为当日订单
        isToday(timestamp) {
            // 将时间戳转换为Date对象
            let date = new Date(timestamp * 1000); // 时间戳以秒为单位，需乘以1000转换为毫秒

            // 获取当前日期
            let today = new Date();

            // 判断日期是否为今日
            if (
                date.getDate() === today.getDate() &&
                date.getMonth() === today.getMonth() &&
                date.getFullYear() === today.getFullYear()
            ) {
                return true;
            } else {
                return false;
            }
        },
        //获取订单详情
        getOrderInfo() {
            let _this = this;
            this.$UtilsApi.orderShare(this.$route.query.id, {}).then(res => {
                if (res.data.code == 0) {
                    if (!res.data.data.group_status) {
                        if (res.data.data.order_id) {
                            this.$dialog.alert({
                                title: res.data.msg == 'group_success' ? '拼团成功!' : '拼团失败',
                                message: '点击确认前往订单详情',
                            }).then(() => {
                                localStorage.setItem('login_data', JSON.stringify({
                                    name: 'ptOrderInfo',
                                    id: res.data.data.order_id
                                }));
                                _this.toHome();
                            });
                        } else {
                            this.$dialog.alert({
                                title: '该团已满员!',
                                message: '点击确认查看门票详情',
                            }).then(() => {
                                localStorage.setItem('login_data', JSON.stringify({
                                    name: 'single',
                                    id: res.data.data.ticket_id
                                }));
                                _this.toHome();
                            });
                        }
                        return
                    };
                    res.data.data.is_cy = false;
                    if (res.data.data.group_member) {
                        res.data.data.group_member.forEach(item => {
                            if (item.member.id == localStorage.getItem('user_id')) {
                                res.data.data.is_cy = true;
                            }
                        });
                    }
                    this.orderInfo = res.data.data;

                    function countdown(pay_time, work_time) {
                        // 计算结束时间戳（单位：毫秒）
                        var end_time = new Date(pay_time + work_time * 60 * 60 * 1000).getTime();

                        // 获取当前时间戳
                        var current_time = new Date().getTime();

                        // 计算剩余时间（单位：毫秒）
                        var remaining_time = end_time - current_time;

                        // 如果剩余时间小于等于0，表示已经过了结束时间
                        if (remaining_time <= 0) {
                            return [0, 0, 0, 0]; // 返回全为0的数组表示倒计时结束
                        }

                        // 计算时、分、秒、毫秒
                        var hours = Math.floor(remaining_time / (1000 * 60 * 60));
                        var minutes = Math.floor((remaining_time % (1000 * 60 * 60)) / (1000 * 60));
                        var seconds = Math.floor((remaining_time % (1000 * 60)) / 1000);
                        var milliseconds = remaining_time % 1000;

                        // 返回时、分、秒、毫秒的数组
                        _this.timer = [hours < 10 ? '0' + hours : hours, minutes < 10 ? '0' + minutes : minutes, seconds < 10 ? '0' + seconds : seconds, Math.floor(milliseconds / 100) < 10 ? '0' + Math.floor(milliseconds / 100) : Math.floor(milliseconds / 100)];
                        // 每隔10毫秒更新倒计时显示
                        setTimeout(function() {
                            countdown(_this.orderInfo.group_member[0].group_order.pay_time * 1000, _this.orderInfo.group_cnf.work_time);
                        }, 100);
                    };
                    countdown(_this.orderInfo.group_member[0].group_order.pay_time * 1000, _this.orderInfo.group_cnf.work_time);
                    this.orderInfo.is_today = this.getTimerState(res.data.data.act_end_time) > 0 || (res.data.data.status == 1 && !this.isToday(res.data.data.order_time));
                } else {
                    this.$notify({ type: 'warning', message: res.data.msg, duration: 2000 });
                }
            });
        },
        // 根据时间戳转换日期
        getNewDate(timer) {
            var timestr = new Date(timer)
            var year = timestr.getFullYear();
            var month = timestr.getMonth() + 1;
            var date = timestr.getDate();
            return year + "." + (month < 10 ? '0' + month : month) + "." + (date < 10 ? '0' + date : date);
        },
        gotoPay() {
            if (!localStorage.getItem('user_token')) {
                localStorage.setItem('login_data', JSON.stringify({
                    name: 'share',
                    id: this.$route.query.id
                }));
                window.location.replace('//paradise.h5.airland100.com/#/login');
                return
            };
            let data = {
                type: this.orderInfo.ticket.type == 1 ? 'single' : this.orderInfo.ticket.type == 2 ? 'weeks' : 'free',
                id: this.orderInfo.ticket.id,
                is_pt: 1,
                group_ticket_id: this.orderInfo.group_cnf.id,
                group_order_id: this.orderInfo.group_member[0].group_order.id
            };
            this.$router.push({
                name: 'info/pay',
                query: data
            });

        },
        // 根据时间戳转换日期
        getNewDate(timer) {
            var timestr = new Date(timer)
            var year = timestr.getFullYear();
            var month = timestr.getMonth() + 1;
            var date = timestr.getDate();
            return year + "." + (month < 10 ? '0' + month : month) + "." + (date < 10 ? '0' + date : date);
        },
        // 根据时间戳转换日期
        getDate(time) {
            var timestr = new Date(parseInt(time) * 1000);
            var year = timestr.getFullYear();
            var month = timestr.getMonth() + 1;
            var date = timestr.getDate();
            var hour = timestr.getHours();
            var minute = timestr.getMinutes();
            var second = timestr.getSeconds();
            return year + "-" + (month < 10 ? '0' + month : month) + "-" + (date < 10 ? '0' + date : date) + " " + (hour < 10 ? '0' + hour : hour) + ":" + (minute < 10 ? '0' + minute : minute) + ":" + (second < 10 ? '0' + second : second);
        }
    },
}